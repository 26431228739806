import './App.css'
import { ThemeProvider } from 'styled-components'
import { lusanTheme } from './Theme/lusanTheme'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './Views/Home/HomePage'
import NavBar from './Components/Nav/NavBar'
import AboutPage from './Views/About/AboutPage'
import ContactPage from './Views/Contact/ContactPage'
import Footer from './Components/Footer/Footer'
import { GlobalStyle } from './Components/Nav/NavComponents'
import FurtherReadingPage from './Views/FurtherReading/FurtherReadingPage'
import { useState } from 'react'
import Sidebar from './Components/Sidebar/Sidebar'
import { IParams } from './Interfaces/IParams'
import FaqPage from './Views/FAQ/FaqPage'

function App() {
    const [isOpen, setIsOpen] = useState(false)

    function toggle() {
        console.log('Toggle called')
        setIsOpen(!isOpen)
    }

    const params: IParams = {
        isOpen: isOpen,
        toggle: toggle,
    }

    return (
        <div>
            <ThemeProvider theme={lusanTheme}>
                <BrowserRouter>
                    <GlobalStyle />
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    )
}

export default App
