import styled from 'styled-components'

const TextPrimary = styled.div`
    display: inline-flex;
    margin-bottom: 10px;
    justify-content: center;
    width: 60vw;
    font-size: ${props => props.theme.fontSizes.p.medium};
    white-space: normal;
    color: ${props => props.theme.palette.black};
`

const TextSecondary = styled.div`
    display: inline-flex;
    margin-bottom: 5px;
    width: 60vw;
    font-size: ${props => props.theme.fontSizes.p.medium};
    white-space: normal;
    color: ${props => props.theme.palette.charcoal};
`

const TextMainHeading = styled.div`
    width: 60vw;
    display: inline-flex;
    margin-bottom: 15px;
    white-space: normal;
    justify-content: center;
    font-size: ${props => props.theme.fontSizes.title.medium};
    color: ${props => props.theme.palette.black};
`

const TextSubHeading = styled.div`
    width: 60vw;
    display: inline-flex;
    margin-bottom: 15px;
    white-space: normal;
    justify-content: left;
    font-size: ${props => props.theme.fontSizes.title.small};
    color: ${props => props.theme.palette.black};
`

const PageWrapper = styled.nav`
    display: flex;
    width: 100vw;
    padding: 10px 0 15px 0;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-size: cover;
    white-space: nowrap;
    overflow: hidden;
`

const Spacer = styled.div`
    display: inline-flex;
    margin: 15px;
`

const FooterALink = styled.a`
    width: 10vw;
    min-width: 70px;
    height: 40px;
    text-decoration: 'none';
    color: black;
`

const ExternalLink = styled(FooterALink)`
    cursor: pointer;
    ::selection {
        color: ${props => props.theme.palette.secondary};
    }
`

const ImageContainer = styled.div`
    display: inline-block;
    height: 60px;
    flex-direction: row;
    overflow: hidden;
    flex-grow: 0;
    margin: 10px;
    margin-left: 5px;
`

export {
    TextPrimary,
    TextSecondary,
    TextMainHeading,
    PageWrapper,
    TextSubHeading,
    Spacer,
    ExternalLink,
    ImageContainer,
}
