import styled from 'styled-components'
import lusanLogo from '../../Assets/lusan-logo.png'
import forest from '../../Assets/forest.png'
import { Link } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle` 
 body {    
    color: ${props => props.theme.palette.white};  
    width: 100%;
    height: 100%;
    min-height: 400px;
    margin-top: 100px;
    margin-bottom: 100px;
    background: ${props => props.theme.palette.background};
    clear: both;
    }`

const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        transform: translate(-100%, 60%);
        font-size: ${props => props.theme.fontSizes.nav.small};
        cursor: pointer;
        color: ${props => props.theme.palette.black};
    }
`

const NavWrapper = styled.nav`
    display: flex;
    height: 80px;
    width: 100vw;
    padding: 10px 0 15px 0;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    background: ${props => props.theme.palette.banner};
    border-bottom: 1px solid #555;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`

const NavItem = styled(Link)`
    text-decoration: 'none';
    color: black;
    cursor: pointer;
    font-size: ${props => props.theme.fontSizes.nav.small};
    font-weight: 500;
    padding: 10px;
    margin: 10px;
    &:hover {
        color: ${props => props.theme.palette.primary};
    }
    ::selection {
        color: ${props => props.theme.palette.secondary};
    }
`

const NavContainer = styled.div`
    display: inline-block;
    height: 50px;
    flex-direction: row;
    overflow: hidden;
`

const NavLogo = styled(NavItem)`
    flex-grow: 0;
    cursor: pointer;
    background-image: url(${lusanLogo});
    background-size: 91px 91px;
    height: 91px;
    width: 91px;
    margin-left: 10px;
    margin-top: 0;
`

const NavLinks = styled(NavContainer)`
    display: inline-flex;
    justify-content: center;
    margin-right: 10%;
    width: 85%;
    a:link {
        text-decoration: none;
    }
    a:visited {
        text-decoration: none;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export { NavItem, NavLogo, NavWrapper, NavLinks, GlobalStyle, MobileIcon }
