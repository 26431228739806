import { PageWrapper, TextMainHeading, TextPrimary } from '../ThemeComponents'

export default function HomePage() {
    return (
        <>
            <PageWrapper>
                <TextMainHeading>Lusan Co-op</TextMainHeading>
                <TextPrimary>
                    Lusan Coop no longer exists, thank you for your support
                </TextPrimary>
            </PageWrapper>
        </>
    )
}
