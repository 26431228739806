import { DefaultTheme } from 'styled-components'

export const lusanTheme: DefaultTheme = {
    palette: {
        white: '#ffffff',
        black: '#000000',
        background: '#BBADFF',
        banner: '#7B9E87',
        primary: '#4B1D3F',
        secondary: '#EDFEC2',
        charcoal: '#313534',
    },
    fontSizes: {
        title: {
            mobile: '1rem',
            small: '1.5rem',
            medium: '3rem',
            large: '4rem',
        },
        p: {
            mobile: '.375rem',
            small: '.75rem',
            medium: '1rem',
            large: '1.5rem',
        },
        nav: {
            mobile: '.75rem',
            small: '1.3rem',
            medium: '2rem',
            large: '3rem',
        },
    },
    mediaQueries: {
        below768: `only screen and (max-width: 768px)`,
    },
    spacers: {
        large: '3rem',
    },
}
